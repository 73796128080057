<template>
  <NavBar></NavBar>
  <v-container fluid class="banner">
    <v-row class="d-flex justify-center align-center mt-lg-5 h-screen">
      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        class="d-none d-md-block d-lg-block"
      ></v-col>

      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        class="d-flex justify-center align-center"
      >
        <div class="login form-sign-in glass-2 text-white">
          <div class="py-2 title">
            <h3 class="fw-bolder">WELCOME BACK</h3>
            <p class="text-white">Login With Your Credentials</p>
          </div>
          <form @submit.prevent="signInUser">
            <v-text-field
              label="Email Address"
              type="email"
              clearable
              class="mb-2"
              @keydown="loginErrorDisplay"
              density="compact"
              variant="outlined"
              v-model.trim="email"
              required
              hint="Enter your email address"
            />

            <v-text-field
              label="Password"
              type="password"
              clearable
              class="mb-2"
              @keydown="loginErrorDisplay"
              density="compact"
              variant="outlined"
              v-model.trim="password"
              required
              hint="Enter your password"
            />

            <!-- Login Error Message -->
            <small v-if="loginError" class="my-2 mx-5 mx-lg-0">
              {{ errorMessage }}
            </small>

            <!-- SUBMIT BUTTON -->
            <v-btn
              block
              type="submit"
              variant="elevated"
              color="green-darken-2"
              :loading="wheel"
              class="mt-3 text-white"
            >
              Sign In
            </v-btn>

            <div class="row mt-1">
              <small class="col">
                <a class="text-white" href="#" @click="resetPassword"
                  >Forgot Password</a
                >
              </small>
              <small class="col">
                <NuxtLink class="text-white" to="/apply">Sign-Up</NuxtLink>
              </small>
            </div>
          </form>
          <div class="mt-4">
            <v-divider type="solid">
              <small>Home of Solutions&copy;</small>
            </v-divider>
            <!-- <p class=""></p> -->
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      wheel: false,
      loginError: true,
      errorMessage: "",

      email: "",
      password: "",
    };
  },

  methods: {
    async resetPassword() {
      try {
        const email = this.email;
        const credentials = await resetPassword(email);
        alert("Password Reset email sent to your email");
      } catch (error) {
        alert("ERROR, Enter a Valid Email and try again");
      }
    },

    async signInUser() {
      try {
        this.wheel = true;
        const router = useRouter();
        const email = this.email;
        const password = this.password;
        const credentials = await signInUser(email, password);
        // console.log("Login Successful", credentials);
        router.push("/personal");
      } catch (error) {
        this.loginError = true;
        this.wheel = false;
        console.error("Sign-in error:", error.message);

        // Now you can access error.code directly
        switch (error.code) {
          case "auth/invalid-email":
            this.errorMessage = "Invalid email";
            break;
          case "auth/user-not-found":
            this.errorMessage = "Email not registered";
            break;
          case "auth/wrong-password":
            this.errorMessage = "Wrong password";
            break;
          case "auth/network-request-failed":
            this.errorMessage = "No internet connection";
            break;
          case "auth/too-many-requests":
            this.errorMessage =
              "Too many failed attempts, please try again later";
            break;
          case "auth/email-already-in-use":
            this.errorMessage = "Email is already in use";
            break;
          default:
            this.errorMessage = "Network Unavailable, try again";
            break;
        }
      }
    },

    loginErrorDisplay() {
      this.loginError = false;
    },
  },
};
</script>

<style scoped>
.form-sign-in {
  width: 60%;
  padding: 2.5rem 1rem;
  /* background-color: aliceblue; */
  color: black;
}

.banner {
  width: 100%;
  height: 100dvh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.686), rgba(0, 0, 0, 0.541)),
    url(/images/tutor2.jpg);
  object-fit: cover;
  background-size: cover;
  overflow: hidden;
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 620px) {
  .form-sign-in {
    color: black;
    width: 90dvw;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
